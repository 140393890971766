<template>
  <v-container fluid>
    <v-row
      dense
      @click="$emit('showElementDetail')"
      :style="{ cursor: 'pointer' }"
    >
      <v-col cols="8" class="font-weight-bold black--text mr-auto">
        {{ element.name }}
      </v-col>
      <v-col cols="4" align="right">
        <v-badge inline :value="imageCount > 0" :content="imageCount">
          <v-icon :color="imageCount > 0 ? 'msaBlue' : 'grey'" class="ml-4">
            mdi-image-outline
          </v-icon>
        </v-badge>
        <v-badge inline :value="noteCount > 0" :content="noteCount">
          <v-icon :color="noteCount > 0 ? 'msaBlue' : 'grey'" class="ml-4">
            mdi-sticker-text-outline
          </v-icon>
        </v-badge>
        <v-badge inline :content="attachmentCount" :value="attachmentCount > 0">
          <v-icon :color="attachmentCount > 0 ? 'orange' : 'grey'" class="ml-4">
            mdi-paperclip
          </v-icon>
        </v-badge>
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
      >
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-btn-toggle v-model="statusId" :mandatory="statusId > 0" dense>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.SATISFACTORY"
            active-class="msaBlue white--text"
            :class="{ 'disable-events': !isEditable }"
            @click.stop
          >
            Yes
          </v-btn>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY"
            active-class="msaBlue white--text"
            :class="{ 'disable-events': !isEditable }"
            @click.stop
          >
            No
          </v-btn>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE"
            active-class="msaBlue white--text"
            @click.stop
            :class="{ 'disable-events': !isEditable }"
          >
            N/A
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="userResponseText"
          auto-grow
          dense
          counter
          maxlength="4000"
          outlined
          placeholder="Optional answer"
          rows="1"
          @click.stop
          :readonly="!isEditable"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'YesNo',

  props: {
    element: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
  },
  computed: {
    imageCount() {
      return this.element.images.length;
    },
    attachmentCount() {
      return this.element.attachments.length;
    },
    noteCount() {
      return this.element.notes.length;
    },
    userResponseText: {
      get() {
        return this.element.userResponseText;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'userResponseText',
          value,
        });
      },
    },
    statusId: {
      get() {
        return this.element.statusId;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'statusId',
          value,
        });
      },
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" width="800px" height="80vh" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Report Recipients </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="newRecipientEmail"
              :rules="isEmailValid"
              outlined
              dense
              label="Enter recipient's email"
            />
          </v-col>
          <v-col>
            <v-btn
              color="msaBlue white--text"
              @click="addRecipient"
              :disabled="isNewRecipientButtonDisabled"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="recipientHeaders"
              :items="recipients"
              hide-default-footer
            >
              <template v-slot:[`item.recipient`]="{ item }">
                {{ item.email }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteRecipient(item)" icon small>
                      <v-icon color="red"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  Remove recipient
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" color="red" dark text> CANCEL </v-btn>
        <v-btn
          @click="generateReport"
          class="msaBlue white--text"
          :disabled="!hasRecipients"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormReportDialog',
  data() {
    return {
      filters: {},
      template: {},
      dialog: false,
      newRecipientEmail: '',
      recipients: [],
      recipientHeaders: [
        {
          text: 'Recipient',
          value: 'recipient',
          class: 'headerTextGrey--text lightGrey',
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          class: 'headerTextGrey--text lightGrey',
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isEmailValid() {
      return [
        this.$helpers.validateEmail(
          this.newRecipientEmail,
          this.recipients.length ? false : true,
          true,
        ),
      ];
    },
    isNewRecipientButtonDisabled() {
      return (
        !this.$helpers.isValidEmail(this.newRecipientEmail) ||
        !this.newRecipientEmail.trim()
      );
    },
    hasRecipients() {
      return this.recipients.length > 0;
    },
  },
  methods: {
    open(filters, template) {
      this.filters = filters;
      this.template = template;
      this.addDefaultRecipient();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.resetDialogValues();
    },

    addDefaultRecipient() {
      const email = this.$store.getters.user.email;
      if (email != '') {
        this.recipients.push({ email: email });
      }
    },
    resetDialogValues() {
      this.newRecipientEmail = '';
      this.recipients = [];
    },
    addRecipient() {
      if (
        this.recipients.some(
          (recipient) => recipient.email == this.newRecipientEmail,
        )
      ) {
        this.newRecipientEmail = '';
        return;
      }

      this.recipients.push({ email: this.newRecipientEmail });
      this.newRecipientEmail = '';
    },
    deleteRecipient(recipientToRemove) {
      const indexToRemove = this.recipients.findIndex(
        (recipient) => recipient.email == recipientToRemove.email,
      );
      this.recipients.splice(indexToRemove, 1);
    },
    generateReport() {
      const url = 'create-form-report?format=json';
      const params = {
        loaderText: 'Loading...',
        formTemplateId: this.template.id,
        filters: this.filters,
        recipients: this.recipients.map((recipient) => recipient.email),
      };

      this.$axios
        .post(url, params)
        .then(() => {
          this.$root.showMessage(
            'Exporting',
            'The export will be emailed when ready.',
            () => {},
            null,
            'OK',
            null,
            false,
            false,
          );
          this.close();
        })
        .catch(() => {});
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <GeneralInformation
              :companyDetail="companyDetail"
              :disableAll="disableAll"
              :disableSave="disableSave"
              :loading="loading"
              @save="saveDetails()"
              @update="updateInfo($event)"
            />
          </v-col>
          <v-col v-if="!isNew" cols="12">
            <CompanyLogo :hasLogo="hasLogo" @toggleHasLogo="onToggleHasLogo" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <AccountInformation
              :companyDetail="companyDetail"
              :disableAll="disableAll"
              :disableSave="disableSave"
              :loading="loading"
              :subscriptionTermTypes="subscriptionTermTypes"
              :subscriptionTypes="subscriptionTypes"
              @save="saveDetails()"
              @update="updateInfo($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountInformation from '@/components/CompanyDetail/AccountInformation.vue';
import CompanyLogo from '@/components/CompanyDetail/CompanyLogo.vue';
import GeneralInformation from '@/components/CompanyDetail/GeneralInformation.vue';
export default {
  name: 'CompanyDetails',
  components: {
    AccountInformation,
    CompanyLogo,
    GeneralInformation,
  },
  data() {
    return {
      companyId: this.$route.params.companyId,
      companyDetail: {},
      loading: true,
      edited: {},
      disableAll: false,
      localStartTime: '',
      localRenewalTime: '',
      subscriptionTypes: [],
      subscriptionTermTypes: [],
      hasLogo: false,
      safeDates: {
        subscriptionStartDate: '',
        renewalDate: '',
      },
    };
  },
  computed: {
    isNew() {
      return this.companyId == -1;
    },
    disableSave() {
      return !this.hasChange || !this.validate;
    },
    hasChange() {
      let result = false;
      for (const key in this.companyDetail) {
        if (this.companyDetail[key] != this.edited[key]) {
          result = true;
          break;
        }
      }
      return result;
    },
    validate() {
      return (
        this.edited.name &&
        this.edited.name.trim().length > 0 &&
        (this.edited.phone.trim().length > 0
          ? this.$helpers.validatePhone(this.edited.phone) === true
          : true) &&
        this.edited.subscriptionTypeId != null &&
        this.edited.subscriptionTermTypeId != null &&
        !isNaN(this.edited.subscriptionTerm) &&
        this.edited.subscriptionTerm > 0 &&
        this.edited.subscriptionTerm % 1 === 0 &&
        (this.edited.paidLicenses != ''
          ? /^(0|-?[1-9][0-9]*)$/.test(this.edited.paidLicenses)
          : true) &&
        (this.edited.groupPaidLicenses != ''
          ? /^(0|-?[1-9][0-9]*)$/.test(this.edited.groupPaidLicenses)
          : true) &&
        (this.edited.licenses != ''
          ? /^(0|-?[1-9][0-9]*)$/.test(this.edited.licenses)
          : true) &&
        (this.edited.groupLicenses != ''
          ? /^(0|-?[1-9][0-9]*)$/.test(this.edited.groupLicenses)
          : true)
      );
    },
  },
  watch: {
    companyDetail: {
      handler(val) {
        if (val.status) {
          this.disableAll = false;
        } else {
          this.disableAll = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getSubscriptionData().then(() => {
      if (this.isNew) {
        this.generateDetailForNewCompany();
      } else {
        this.getCompany();
      }
    });
  },
  methods: {
    onToggleHasLogo() {
      this.hasLogo = !this.hasLogo;
    },
    initHadLogo() {
      this.hasLogo =
        this.companyDetail.logoGuid == undefined
          ? false
          : this.companyDetail.logoGuid.length > 0;
    },
    getCompany() {
      const params = {
        id: this.companyId,
        loaderText: 'Loading...',
      };

      const url = 'get-company-details?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.companyDetail = this.numberToBoolean(response.data);
          this.formatDates();
          this.$store.commit('updateSelectedCompany', {
            id: response.data.id,
            name: response.data.name,
            status: this.companyDetail.status,
            companyGroupId: this.companyDetail.companyGroupId,
          });
          this.edited = { ...this.companyDetail };
          this.initHadLogo();
          this.loading = false;
        })
        .catch((error) => error);
    },
    updateInfo(info) {
      if (info.subscriptionStartDate) {
        this.safeDates.subscriptionStartDate = info.subscriptionStartDate;
      }

      if (info.renewalDate) {
        this.safeDates.renewalDate = info.renewalDate;
      }

      Object.assign(this.edited, info);
    },
    numberToBoolean(rawData) {
      const booleanKeys = [
        'contractorEnabled',
        'contractorLmsEnabled',
        'isApiEnabled',
        'isCourseBuilderEnabled',
        'isDuplicateCoursesEnabled',
        'isUserNotificationEnabled',
        'isStandardPricing',
        'isTestAccount',
        'labelEnabled',
        'metadataEnabled',
        'schedulerEnabled',
      ];

      booleanKeys.forEach((key) => {
        rawData[key] = !!rawData[key];
      });

      return rawData;
    },
    formatDates() {
      if (this.companyDetail.subscriptionStartDate) {
        this.companyDetail.subscriptionStartDate =
          this.$options.filters.filterAsLocalDate(
            this.companyDetail.subscriptionStartDate,
          );
      }

      if (this.companyDetail.renewalDate) {
        this.companyDetail.renewalDate =
          this.$options.filters.filterAsLocalDate(
            this.companyDetail.renewalDate,
          );
      }
    },
    saveDetails() {
      this.edited.subscriptionStartDate =
        this.edited.subscriptionStartDate ?? '';
      this.edited.renewalDate = this.edited.renewalDate ?? '';

      if (this.edited.subscriptionStartDate) {
        this.edited.subscriptionStartDate = this.$helpers.formatDateTimeToUTC(
          this.edited.subscriptionStartDate,
        );
      }

      if (this.edited.renewalDate) {
        this.edited.renewalDate = this.$helpers.formatDateTimeToUTC(
          this.edited.renewalDate,
        );
      }

      const params = {
        details: this.edited,
        loaderText: 'Saving...',
        snackText: 'Saved.',
      };

      const url = this.isNew
        ? 'create-company?format=json'
        : 'update-company-details?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.isNew) {
            this.$store.commit('updateSelectedCompany', {
              id: response.data.id,
              name: response.data.name,
              status: 1,
            });
            this.companyId = this.$store.getters.selectedCompany.id;
            this.$router.replace({
              name: this.$route.name,
              params: { companyId: this.companyId },
            });
          }
          this.loading = true;
          this.getCompany();
        })
        .catch((error) => {
          //Restore safe date values due to error after formatted date used in v-date-picker in AccountInformation comp.
          this.edited.subscriptionStartDate =
            this.safeDates.subscriptionStartDate;
          this.edited.renewalDate = this.safeDates.renewalDate;

          return error;
        });
    },
    generateDetailForNewCompany() {
      this.companyDetail = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        companyGroupId: 0,
        companyGroupName: '',
        contractorEnabled: true,
        contractorLmsEnabled: false,
        description: '',
        id: -1,
        isApiEnabled: false,
        isCourseBuilderEnabled: false,
        isDuplicateCoursesEnabled: false,
        isUserNotificationEnabled: true,
        isStandardPricing: true,
        is1LifeMobileSettingEnabled: false,
        isTestAccount: false,
        labelEnabled: true,
        logoGuid: '',
        metadataEnabled: true,
        licenses: 0,
        paidLicenses: 0,
        groupPaidLicenses: 0,
        groupLicenses: 0,
        name: '',
        phone: '',
        postalCode: '',
        province: '',
        renewalDate: null,
        schedulerEnabled: true,
        subscriptionStartDate: null,
        subscriptionTerm: 1,
        subscriptionTermTypeId: null,
        subscriptionTypeId: null,
        status: 1,
        activeUsersCount: 0,
        showScoreQuestion: 0,
      };
      this.$store.commit('updateSelectedCompany', {
        id: this.companyDetail.id,
        name: 'New company',
        status: 1,
      });
      this.edited = { ...this.companyDetail };
      this.loading = false;
    },
    getSubscriptionData() {
      const url = 'get-subscription-data?format=json';

      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => {
          this.subscriptionTypes = response.data.types;
          this.subscriptionTermTypes = response.data.terms;
        })
        .catch((error) => error);
    },
  },
};
</script>
<style></style>

export const MENU = [
  {
    title: 'Home',
    routeName: 'UserHome',
    submenu: [],
  },
  {
    title: 'Administration',
    routeName: '',
    submenu: [
      {
        title: 'Settings',
        routeName: 'CompanySettings',
      },
      {
        title: 'Mobile App',
        routeName: 'MobileSettings',
      },
      {
        title: 'Users',
        routeName: 'CompanyUsers',
      },
      {
        title: 'Projects',
        routeName: 'CompanyProjects',
      },
      {
        title: 'Documents',
        routeName: 'CompanyDocumentLibrary',
      },
      {
        title: 'Forms',
        routeName: 'CompanyForms',
        query: {
          reset: 1,
          value: { headers: [], filters: {}, customPageView: {}, page: 1 },
        },
      },
      {
        title: 'Training',
        routeName: 'CompanyTrainingRecords',
        query: {
          reset: 1,
          value: {
            expandedGroupId: -1,
            options: {
              page: 1,
              itemsPerPage: 20,
              sortBy: ['name'],
              sortDesc: [false],
            },
          },
        },
      },
      {
        title: 'Scheduler (Mobile)',
        routeName: '',
        action:
          process.env.VUE_APP_BASE_URL +
          'security-service/cht-to-scheduler?format=json',
      },
      {
        title: 'Reports',
        routeName: 'FormsReport',
      },
      {
        title: 'Contractor Manager',
        routeName: '',
        action:
          process.env.VUE_APP_BASE_URL +
          'security-service/cht-to-contractor?format=json',
      },
    ],
  },
  {
    title: 'Documents',
    routeName: 'UserDocumentLibrary',
    submenu: [
      {
        title: 'Requiring My Attention',
        routeName: 'RequiringMyAttention',
      },
      {
        title: 'Forms',
        routeName: 'Forms',
      },
      {
        title: 'Library',
        routeName: 'UserDocumentLibrary',
      },
      {
        title: 'Assigned Documents',
        routeName: 'AssignedDocuments',
      },
      {
        title: 'Drafts',
        routeName: 'FormDrafts',
        query: {
          reset: 1,
          value: { selectedDrafts: [] },
        },
      },
    ],
  },
  {
    title: 'Training',
    routeName: 'Tasks',
    submenu: [
      {
        title: 'Training',
        routeName: 'Tasks',
      },
      {
        title: 'Online Training',
        routeName: '',
        action:
          process.env.VUE_APP_BASE_URL +
          'security-service/cht-to-lms?format=json&redirect=EmployeeMyCourses',
      },
    ],
  },
];

export const USER_DETAILS_SUB_MENU = (store) =>
  store.getters.selectedUser.id == 0
    ? [
        {
          title: 'Details',
          routeName: 'UserDetails',
        },
      ]
    : [
        {
          title: 'Details',
          routeName: 'UserDetails',
        },
        {
          title: 'Notifications',
          routeName: 'UserNotifications',
        },
        {
          title: 'Projects',
          routeName: 'UserProjects',
        },
        {
          title: 'Forms',
          routeName: 'UserForms',
        },
        {
          title: 'Documents',
          routeName: 'UserAssignedDocuments',
        },
        {
          title: 'Training',
          routeName: 'UserTrainingRecords',
        },
        {
          title: 'Tasks',
          routeName: 'UserTasks',
        },
        {
          title: 'Notes',
          routeName: 'UserNotes',
        },
      ];

export const USERS_SUB_MENU = [
  {
    title: 'Users',
    routeName: 'CompanyUsers',
  },
  {
    title: 'Groups',
    routeName: 'CompanyGroups',
  },
  {
    title: 'Contacts',
    routeName: 'CompanyContacts',
  },
];

export const ADMIN_DOCUMENTS_SUB_MENU = [
  {
    title: 'Library',
    routeName: 'CompanyDocumentLibrary',
  },
  {
    title: 'Assigned Documents',
    routeName: 'CompanyAssignedDocuments',
  },
  {
    title: 'Resources',
    routeName: 'CompanyResources',
  },
];

export const DOCUMENTS_SUB_MENU = [
  {
    title: 'Requiring My Attention',
    routeName: 'RequiringMyAttention',
  },
  {
    title: 'Forms',
    routeName: 'Forms',
  },
  {
    title: 'Library',
    routeName: 'UserDocumentLibrary',
  },
  {
    title: 'Assigned Documents',
    routeName: 'AssignedDocuments',
  },
  {
    title: 'Drafts',
    routeName: 'FormDrafts',
    query: {
      reset: 1,
      value: { selectedDrafts: [] },
    },
  },
];

export const ADMIN_FORMS_SUB_MENU = [
  {
    title: 'Filled Out Forms',
    routeName: 'CompanyForms',
    query: {
      reset: 1,
      value: { headers: [], filters: {}, customPageView: {}, page: 1 },
    },
  },
  {
    title: 'Form Templates',
    routeName: 'FormTemplates',
  },
  {
    title: 'Form Tags',
    routeName: 'FormTags',
  },
  {
    title: 'Form Exporter',
    routeName: '',
    action:
      process.env.VUE_APP_BASE_URL +
      'security-service/cht-to-form-tools?format=json',
  },
  {
    title: 'Mass Finalize',
    routeName: '',
    action:
      process.env.VUE_APP_BASE_URL +
      'security-service/cht-to-form-tools?format=json',
  },
  {
    title: 'Mass Archive',
    routeName: 'MassArchiveForms',
  },
];

export const FORM_TEMPLATE_SUB_MENU = (store) =>
  store.getters.selectedFormTemplate.id == 0
    ? [
        {
          title: 'Form Builder',
          routeName: 'FormTemplateDetail',
        },
      ]
    : [
        {
          title: 'Form Builder',
          routeName: 'FormTemplateDetail',
        },
        {
          title: 'Preview',
          routeName: 'FormTemplatePreview',
        },
      ];

export const LABEL_SUB_MENU = [
  {
    title: 'Users',
    routeName: 'CompanyGroupedEmployees',
  },
  {
    title: 'Documents',
    routeName: 'CompanyGroupedDocuments',
  },
  {
    title: 'Courses',
    routeName: 'CompanyGroupedCourses',
  },
  {
    title: 'Task Groups',
    routeName: 'CompanyGroupedTaskGroups',
  },
];

export const TRAINING_LABEL_SUB_MENU = [
  {
    title: 'Users',
    routeName: 'CompanyTrainingGroupedEmployees',
  },
  {
    title: 'Documents',
    routeName: 'CompanyTrainingGroupedDocuments',
  },
  {
    title: 'Courses',
    routeName: 'CompanyTrainingGroupedCourses',
  },
  {
    title: 'Task Groups',
    routeName: 'CompanyTrainingGroupedTaskGroups',
  },
];

export const REPORTS_SUB_MENU = [

  {
    title: 'Forms',
    routeName: 'FormsReport',
  },
  {
    title: 'Corrective Actions',
    routeName: 'CorrectiveActionsReport',
  },
  {
    title: 'Training Records',
    routeName: 'TrainingRecordsReport',
  },
  {
    title: 'Tasks',
    routeName: 'TrainingTasksReport',
  },
  {
    title: 'Online Courses Details',
    routeName: '',
    action:
      process.env.VUE_APP_BASE_URL +
      'security-service/cht-to-lms?format=json&redirect=WebAdminCourseReports',
  },
  {
    title: 'Users By Schedule',
    routeName: 'ScheduleUsersReport',
  },
];

export const ADMIN_TRAINING_SUB_MENU = [
  {
    title: 'Training Records',
    routeName: 'CompanyTrainingRecords',
    query: {
      reset: 1,
      value: {
        expandedGroupId: -1,
        options: {
          page: 1,
          itemsPerPage: 20,
          sortBy: ['name'],
          sortDesc: [false],
        },
      },
    },
  },
  {
    title: 'Learning Management System',
    routeName: '',
    action:
      process.env.VUE_APP_BASE_URL + 'security-service/cht-to-lms?format=json',
  },
  {
    title: 'Groups',
    routeName: 'TrainingGroups',
  },
  {
    title: 'Tasks',
    routeName: 'TaskTemplates',
    query: {
      reset: 1,
      value: {
        expandedGroupIds: [],
      },
    },
  },
];

export const TRAINING_SUB_MENU = [
  {
    title: 'Tasks',
    routeName: 'Tasks',
  },
  {
    title: 'Training Records',
    routeName: 'TrainingRecords',
  },
  {
    title: 'Online Training',
    routeName: '',
    action:
      process.env.VUE_APP_BASE_URL +
      'security-service/cht-to-lms?format=json&redirect=EmployeeMyCourses',
  },
];

export const PROFILE_SUB_MENU = [
  {
    title: 'Profile',
    routeName: 'UserProfile',
  },
  {
    title: 'Notifications',
    routeName: 'UserProfileNotifications',
  },
];

export const COMPANY_SETTINGS_SUB_MENU = [
  {
    title: 'Settings',
    routeName: 'CompanySettings',
  },
  {
    title: 'Resources',
    routeName: 'CompanyResourceSettings',
  },
  {
    title: 'Custom User Fields',
    routeName: 'CompanyInputFieldSettings',
  },
];

export const MOBILE_APP_SUB_MENU = [
  {
    title: 'Mobile Settings',
    routeName: 'MobileSettings',
  },
  {
    title: 'Sync Logs',
    routeName: 'CompanySyncLogs',
  },
];

<template>
  <v-dialog v-model="dialog" width="750" v-if="element != null">
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Details </v-col>
          <v-col class="shrink">
            <v-btn icon color="white" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row v-if="showCorrectiveActions">
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2">
                <v-badge inline :value="caCount > 0" :content="caCount">
                  Corrective Actions
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(
                              correctiveAction, caIndex
                            ) of element.correctiveActions"
                            :key="correctiveAction.id"
                          >
                            <td
                              :style="{ cursor: 'pointer' }"
                              @click="$emit('showCaDetails', correctiveAction)"
                            >
                              <v-row dense class="py-2" align="center">
                                <v-col
                                  cols="12"
                                  v-if="correctiveAction.lastResolvedAt != ''"
                                  class="grow green--text"
                                >
                                  Last resolved by
                                  {{ correctiveAction.lastResolvedByName }} at
                                  {{
                                    correctiveAction.lastResolvedAt
                                      | filterAsLocalDateTime
                                  }}
                                </v-col>
                                <v-col cols="6" align="left" v-if="isMobile">
                                  <v-icon
                                    :color="
                                      hasCaImage(correctiveAction)
                                        ? 'msaBlue'
                                        : 'grey'
                                    "
                                    >mdi-camera</v-icon
                                  >
                                  <v-icon
                                    :color="
                                      hasCaNote(correctiveAction)
                                        ? 'msaBlue'
                                        : 'grey'
                                    "
                                    >mdi-file</v-icon
                                  >
                                </v-col>
                                <v-col
                                  v-if="isMobile"
                                  cols="6"
                                  align="right"
                                  :style="hazardRankStyle(correctiveAction)"
                                  class="text-h4 font-weight-bold"
                                >
                                  {{ correctiveAction.hazardRankSeverity }}
                                  {{ correctiveAction.hazardRankProbability }}
                                </v-col>
                                <v-col cols="12" md="6">
                                  Hazard: {{ correctiveAction.hazardText }}
                                </v-col>
                                <v-col
                                  v-if="!isMobile"
                                  cols="6"
                                  align="right"
                                  :style="hazardRankStyle(correctiveAction)"
                                  class="text-h4 font-weight-bold"
                                >
                                  {{ correctiveAction.hazardRankSeverity }}
                                  {{ correctiveAction.hazardRankProbability }}
                                </v-col>
                                <v-col cols="12" md="6">
                                  Control: {{ correctiveAction.controlText }}
                                </v-col>
                                <v-col cols="6" align="right" v-if="!isMobile">
                                  <v-icon
                                    :color="
                                      hasCaImage(correctiveAction)
                                        ? 'msaBlue'
                                        : 'grey'
                                    "
                                    >mdi-camera</v-icon
                                  >
                                  <v-icon
                                    :color="
                                      hasCaNote(correctiveAction)
                                        ? 'msaBlue'
                                        : 'grey'
                                    "
                                    >mdi-file</v-icon
                                  >
                                </v-col>
                                <v-col cols="12" md="6">
                                  Assigned To:
                                  {{ correctiveAction.assignedToName }}
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                  :align="isMobile ? 'center' : 'right'"
                                >
                                  <v-btn-toggle
                                    :value="caStatusId(caIndex)"
                                    mandatory
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          :value="1"
                                          width="80"
                                          :class="
                                            correctiveAction.statusId == 1
                                              ? 'msaBlue'
                                              : ''
                                          "
                                          height="40"
                                          @click.stop
                                          @click="
                                            showAddResolvedBy(correctiveAction)
                                          "
                                          :disabled="
                                            !isCaEditable(correctiveAction)
                                          "
                                          v-on="on"
                                        >
                                          <v-icon
                                            :color="
                                              correctiveAction.statusId == 1
                                                ? 'white'
                                                : ''
                                            "
                                            >mdi-check</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span>Resolve</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          :value="2"
                                          width="80"
                                          :class="
                                            correctiveAction.statusId == 2
                                              ? 'red'
                                              : ''
                                          "
                                          height="40"
                                          @click.stop
                                          @click="unresolveCa(correctiveAction)"
                                          :disabled="
                                            !isCaEditable(correctiveAction)
                                          "
                                          v-on="on"
                                        >
                                          <v-icon
                                            :color="
                                              correctiveAction.statusId == 2
                                                ? 'white'
                                                : ''
                                            "
                                            >mdi-close</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span>Unresolve</span>
                                    </v-tooltip>
                                  </v-btn-toggle>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="pb-0"
                                  v-if="isUnsaved(correctiveAction.id)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        icon
                                        v-on="on"
                                        color="red"
                                        @click.stop
                                        @click="$emit('deleteCa', caIndex)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col align="right" class="mt-4" v-if="isEditable">
                    <v-btn
                      class="msaBlue white--text"
                      @click="$emit('showNewCa')"
                    >
                      Add corrective action
                    </v-btn>
                  </v-col>
                </v-row>

                <AddResolvedBy
                  ref="caResolvedBy"
                  :users="users"
                  @cancelled="cancelResolvedBy()"
                  @resolved="addResolvedBy($event)"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2">
                <v-badge inline :value="imageCount > 0" :content="imageCount">
                  Images
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(image, index) of element.images"
                            :key="index"
                          >
                            <td align="center">
                              <v-row no-gutters align="center">
                                <v-col
                                  @click="previewImage(imageSrc(image))"
                                  :style="{ cursor: 'pointer' }"
                                >
                                  <v-img
                                    :src="imageSrc(image)"
                                    contain
                                    :max-width="isMobile ? '200px' : '300px'"
                                    max-height="150px"
                                  >
                                  </v-img>
                                </v-col>
                                <v-col
                                  class="shrink"
                                  v-if="isUnsaved(image.id)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        text
                                        small
                                        v-on="on"
                                        color="red"
                                        @click="$emit('deleteImage', index)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" align="right" v-if="isEditable">
                    <AddImage @addImage="$emit('addImage', $event)" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="showNotes">
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2">
                <v-badge inline :value="noteCount > 0" :content="noteCount">
                  Notes
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(note, index) of element.notes"
                            :key="note.id"
                          >
                            <td>
                              <v-row no-gutters align="center">
                                <v-col class="grow">
                                  <v-row no-gutters>
                                    <v-col cols="12">
                                      {{ note.description }}
                                    </v-col>
                                    <v-col class="text-italic text-caption">
                                      By {{ note.createdByName }} on
                                      {{
                                        isUnsaved(note.id)
                                          ? note.createdOnMobileAt
                                          : note.createdOnMobileAt
                                            | filterAsLocalDateTime
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col class="shrink" v-if="isUnsaved(note.id)">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        text
                                        small
                                        v-on="on"
                                        color="red"
                                        @click="$emit('deleteNote', index)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" align="right" v-if="isEditable">
                    <AddNote @addNote="$emit('addNote', $event)" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2">
                <v-badge
                  inline
                  :value="attachmentCount > 0"
                  :content="attachmentCount"
                >
                  Files
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(attachment, index) of element.attachments"
                            :key="attachment.id"
                          >
                            <td>
                              <v-row align="center">
                                <v-col class="shrink">
                                  <v-icon
                                    :color="
                                      attachmentIconColor(attachment.mimeType)
                                    "
                                  >
                                    {{ attachmentIcon(attachment.mimeType) }}
                                  </v-icon>
                                </v-col>
                                <v-col class="grow">
                                  <v-row no-gutters>
                                    <v-col cols="12">
                                      <a
                                        v-if="!isUnsaved(attachment.id)"
                                        target="_blank"
                                        :href="
                                          attachmentDownloadUrl(attachment.id)
                                        "
                                      >
                                        {{ attachment.filename }}
                                      </a>
                                      <a
                                        v-else
                                        href="#"
                                        @click="
                                          $emit(
                                            'previewUnsavedAttachment',
                                            attachment,
                                          )
                                        "
                                      >
                                        {{ attachment.filename }}
                                      </a>
                                    </v-col>
                                    <v-col class="text-italic text-caption">
                                      {{
                                        attachment.createdAt
                                          | filterAsLocalDateTime
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  class="shrink"
                                  v-if="isUnsaved(attachment.id)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        text
                                        small
                                        v-on="on"
                                        color="red"
                                        @click="
                                          $emit('deleteAttachment', index)
                                        "
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" align="right" v-if="isEditable">
                    <AddFile
                      @onFileUpload="$emit('onFileUpload', $event)"
                      :formInstanceElementId="element.id"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="msaBlue white--text" @click="close()">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import AddNote from '@/components/FormInstances/AddNote.vue';
import AddImage from '@/components/FormInstances/AddImage.vue';
import AddResolvedBy from '@/components/FormInstances/AddResolvedBy.vue';
import AddFile from '@/components/FormInstances/AddFile.vue';

export default {
  name: 'ElementDetails',
  components: {
    AddNote,
    AddImage,
    AddResolvedBy,
    AddFile,
  },
  props: {
    element: {
      type: Object,
    },
    users: {
      type: Array,
    },
    isEditable: {
      type: Boolean,
    },
    isFinalized: {
      type: Boolean,
    },
    selectedElementOriginal: {
      type: Object,
    },
  },
  computed: {
    caStatusId() {
      return (caIndex) => this.element.correctiveActions[caIndex].statusId;
    },
    caCount() {
      return this.element.correctiveActions.length;
    },
    imageCount() {
      return this.element.images.length;
    },
    noteCount() {
      return this.element.notes.length;
    },
    attachmentCount() {
      return this.element.attachments.length;
    },
    showNotes() {
      return (
        this.element.typeId !=
          this.$constants.FORM_ELEMENT_TYPES.INSTRUCTIONS &&
        this.element.typeId != this.$constants.FORM_ELEMENT_TYPES.DATE &&
        this.element.typeId != this.$constants.FORM_ELEMENT_TYPES.TIME
      );
    },
    showCorrectiveActions() {
      return (
        this.element.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM ||
        this.element.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS ||
        (this.element.typeId == this.$constants.FORM_ELEMENT_TYPES.SCORE &&
          this.element.correctiveActionRequired)
      );
    },
    isUnsaved() {
      return (id) => !Number.isInteger(id);
    },
    imageSrc() {
      return (image) => {
        if (this.isUnsaved(image.id)) {
          return image.src;
        } else {
          return this.getImage(image.id);
        }
      };
    },
    hazardRankStyle() {
      return (correctiveAction) => {
        const hazardRank = `${correctiveAction.hazardRankSeverity}${correctiveAction.hazardRankProbability}`;
        if (this.highCa.includes(hazardRank)) {
          return { color: 'red' };
        }

        if (this.mediumCa.includes(hazardRank)) {
          return { color: 'orange' };
        }

        if (this.lowCa.includes(hazardRank)) {
          return { color: 'green' };
        }
      };
    },
    hasCaImage() {
      return (correctiveAction) => correctiveAction.images.length;
    },
    hasCaNote() {
      return (correctiveAction) => correctiveAction.notes.length;
    },
    isCaEditable() {
      return (ca) => {
        if (this.isFinalized) {
          return false;
        }
        if (this.isEditable) {
          return true;
        }
        if (!Number.isInteger(ca.id)) {
          return true;
        }
        const originalCa = this.selectedElementOriginal.correctiveActions.find(
          (originalCa) => originalCa.id === ca.id,
        );
        return originalCa.assignedStudentUserId == this.$store.getters.user.id;
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      dialog: false,
      highCa: ['1A', '1B', '1C', '1D', '2A', '2B', '2C', '3A', '3B', '4A'],
      mediumCa: ['2D', '3C', '3D', '4B', '4C'],
      lowCa: ['4D', '5A', '5B', '5C', '5D'],
      selectedCa: null,
    };
  },
  methods: {
    previewImage(imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      const newWindow = window.open('');
      newWindow.document.write(image.outerHTML);
    },
    show() {
      this.dialog = true;
    },
    getImage(id) {
      return `${
        process.env.VUE_APP_BASE_URL
      }download-element-image?format=image&id=${id}&jwtToken=${
        this.$store.getters.jwtToken
      }&t=${new Date()}`;
    },
    unresolveCa(correctiveAction) {
      this.selectedCa = correctiveAction;
      this.$emit('selectedCa', correctiveAction);
      const updated = {
        ...this.selectedCa,
        statusId: 2,
        lastResolvedAt: '',
        lastResolvedBy: '',
        lastResolvedByName: '',
      };
      this.$emit('updateCa', JSON.parse(JSON.stringify(updated)));
      this.selectedCa = null;
    },
    addResolvedBy(payload) {
      const updated = {
        ...this.selectedCa,
        statusId: 1,
        lastResolvedAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        lastResolvedBy: payload.id,
        lastResolvedByName: payload.fullname,
      };
      this.$emit('updateCa', JSON.parse(JSON.stringify(updated)));
      this.selectedCa = null;
    },
    showAddResolvedBy(correctiveAction) {
      this.selectedCa = correctiveAction;
      this.$emit('selectedCa', correctiveAction);
      if (this.selectedCa.statusId != 1) {
        this.$refs.caResolvedBy.show();
      }
    },
    cancelResolvedBy() {
      this.selectedCa = null;
      this.$emit('selectedCa', null);
    },
    close() {
      this.selectedCa = null;
      this.dialog = false;
      this.$emit('close');
    },
    attachmentIconColor(mimeType) {
      switch (mimeType) {
        case 'application/pdf':
          return 'red';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
          return 'blue';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          return 'green';
        default:
          return '';
      }
    },
    attachmentIcon(mimeType) {
      switch (mimeType) {
        case 'application/pdf':
          return 'mdi-file-pdf-box';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
          return 'mdi-file-word-box';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          return 'mdi-file-excel-box';
        default:
          return '';
      }
    },
    attachmentDownloadUrl(attachmentId) {
      return (
        process.env.VUE_APP_BASE_URL +
        'download-form-attachment?jwtToken=' +
        this.$store.getters.jwtToken +
        '&id=' +
        attachmentId +
        '&tz=' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
    },
  },
};
</script>

<style></style>

<template>
  <v-container fluid>
    <v-row
      dense
      :style="{ cursor: 'pointer' }"
      @click.self="$emit('showElementDetail')"
    >
      <v-col
        cols="8"
        class="font-weight-bold black--text mr-auto"
        @click.self="$emit('showElementDetail')"
      >
        {{ element.name }}
      </v-col>
      <v-col cols="4" @click.self="$emit('showElementDetail')" align="right">
        <v-badge inline :value="imageCount > 0" :content="imageCount">
          <v-icon
            :color="imageCount > 0 ? 'msaBlue' : 'grey'"
            class="ml-4"
            @click.self="$emit('showElementDetail')"
          >
            mdi-image-outline
          </v-icon>
        </v-badge>
        <v-badge inline :value="noteCount > 0" :content="noteCount">
          <v-icon
            :color="noteCount > 0 ? 'msaBlue' : 'grey'"
            class="ml-4"
            @click.self="$emit('showElementDetail')"
          >
            mdi-sticker-text-outline
          </v-icon>
        </v-badge>
        <v-badge inline :content="attachmentCount" :value="attachmentCount > 0">
          <v-icon
            :color="attachmentCount > 0 ? 'orange' : 'grey'"
            class="ml-4"
            @click.self="$emit('showElementDetail')"
          >
            mdi-paperclip
          </v-icon>
        </v-badge>
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
        @click.self="$emit('showElementDetail')"
      >
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="selected"
          dense
          outlined
          :multiple="isMultiSelect"
          :items="element.dropDownOptions"
          item-text="text"
          item-value="id"
          clearable
          :disabled="disableDropDown"
          hide-details
          placeholder="Select option"
        >
          <template v-slot:selection="{ item, index }" v-if="isMultiSelect">
            <v-card flat class="grey lighten-2 ma-1">
              <v-card-text class="pa-1">
                <v-row no-gutters align="center">
                  <v-col>
                    {{ item.text }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      icon
                      small
                      @click.stop="unselect(index)"
                      :disabled="
                        element.statusId ==
                          $constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE ||
                        !isEditable
                      "
                    >
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-slot:selection="{ item }" v-else>
            {{ item.text }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-switch
          v-model="statusId"
          :true-value="$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE"
          :false-value="statusIdFalseValue"
          label="Not applicable"
          dense
          hide-details
          class="ma-0 pa-0"
          color="msaBlue"
          :disabled="!isEditable"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    element: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
  },
  computed: {
    imageCount() {
      return this.element.images.length;
    },
    noteCount() {
      return this.element.notes.length;
    },
    attachmentCount() {
      return this.element.attachments.length;
    },
    selected: {
      get() {
        return this.element.selected;
      },
      set(value) {
        const updatedValue = value ?? (this.isMultiSelect ? [] : 0);
        this.$emit('updateElement', {
          key: 'selected',
          value: updatedValue,
        });

        if (this.isMultiSelect) {
          this.statusId = updatedValue.length
            ? this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
            : this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY;
        } else {
          this.statusId =
            updatedValue > 0
              ? this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
              : this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY;
        }
      },
    },
    isMultiSelect() {
      return (
        this.element.typeId ==
        this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI
      );
    },
    statusId: {
      get() {
        return this.element.statusId;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'statusId',
          value,
        });
      },
    },
    statusIdFalseValue() {
      if (this.isMultiSelect) {
        if (this.selected.length) {
          return this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY;
        } else {
          return this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY;
        }
      }
      return this.selected > 0
        ? this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
        : this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY;
    },
    disableDropDown() {
      return (
        this.statusId == this.$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE ||
        !this.isEditable
      );
    },
  },
  data() {
    return {
      required: [(v) => !!v || 'This field is required'],
    };
  },
  methods: {
    unselect(index) {
      this.selected = this.selected.toSpliced(index, 1);
    },
  },
};
</script>

<style></style>

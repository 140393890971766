<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="documents.length"
        :value="documents.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
          @click="getData()"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected files
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Management </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col cols="12">
            <CustomizedAutoComplete
              :value="selectedGroup"
              label="Select a folder to move files to"
              hide-details
              clearable
              prepend-icon="mdi-file-move-outline"
              :items="groups"
              @change="selectedGroup = $event"
            />
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              deletable-chips
              :value="selectedLabels"
              :items="labels"
              hide-details
              label="Attach group(s)"
              multiple
              prepend-icon="mdi-tag-multiple-outline"
              clearable
              @change="selectedLabels = $event"
            />
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              deletable-chips
              :value="selectedEmployees"
              :items="employees"
              item-text="fullname"
              clearable
              hide-details
              label="Pick employees to assign"
              multiple
              prepend-icon="mdi-account-multiple"
              @change="selectedEmployees = $event"
            />
          </v-col>
          <v-col v-if="showPublishWarning" cols="12" class="pb-0">
            <v-alert dense type="warning">
              One or more of the following documents is currently assigned to a
              user, attached to a schedule or a group. If you unpublish the
              document(s), it will unassign/remove the document(s) from the
              user, schedule or group.
            </v-alert>
          </v-col>
          <v-col class="shrink pr-0">
            <v-icon>mdi-file-eye-outline</v-icon>
          </v-col>
          <v-col class="pl-2 grow">
            <PublishButtonGroup
              :is-published="publish"
              @click="updatePublish($event)"
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pl-11 text-caption"
            v-if="selectedLabels.length > 0 || selectedEmployees.length > 0"
          >
            Note: In order to attach groups or assign users to the following
            files, any unpublished files will be published.
          </v-col>
          <v-col cols="12" v-if="selectedEmployees.length > 0">
            <v-icon class="pr-2"> mdi-email-multiple-outline </v-icon>
            <v-btn-toggle
              class="pr-2"
              active-class="msaBlue white--text"
              dense
              mandatory
            >
              <v-btn @click="sendEmail = 1"> yes </v-btn>
              <v-btn @click="sendEmail = 0"> no </v-btn>
            </v-btn-toggle>
            Send Email?
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Files</th>
                      <th class="text-right">Remove From Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in documents" :key="item.id">
                      <td>
                        <FileTypeIcon :filename="item.originalFilename" />
                        {{ item.name }}
                      </td>
                      <td align="right">
                        <v-btn icon color="red" @click="unselect(item)">
                          <v-icon> mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red" dark @click="deleteAll()">
          <v-icon>mdi-delete</v-icon>
          delete all
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel">Cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm()"
          :disabled="disableConfirm"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileTypeIcon from '@/components/FileTypeIcon.vue';
import PublishButtonGroup from '@/components/PublishButtonGroup';

export default {
  name: 'PrivateDocumentMassManage',
  components: { FileTypeIcon, PublishButtonGroup },
  props: {
    documents: {
      type: Array,
    },
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      selectedGroup: null,
      selectedLabels: [],
      selectedEmployees: [],
      labels: [],
      employees: [],
      loaded: false,
      publish: null,
      sendEmail: 1,
      deleteAllDialog: false,
    };
  },
  watch: {
    'selectedLabels.length'(val) {
      if (val > 0) {
        this.publish = 1;
      }
    },
    'selectedEmployees.length'(val) {
      if (val > 0) {
        this.publish = 1;
      }
    },
  },
  computed: {
    allLabelsSelected() {
      return this.selectedLabels.length == this.labels.length;
    },
    labelSelected() {
      return this.selectedLabels.length > 0 && !this.allLabelsSelected;
    },
    selectAlliconForLabels() {
      if (this.allLabelsSelected) return 'mdi-checkbox-outline';
      if (this.labelSelected) return 'mdi-minus-box-outline';
      return 'mdi-checkbox-blank-outline';
    },
    allEmployeesSelected() {
      return this.selectedEmployees.length == this.employees.length;
    },
    employeeSelected() {
      return this.selectedEmployees.length > 0 && !this.allEmployeesSelected;
    },
    selectAlliconForEmployees() {
      if (this.allEmployeesSelected) return 'mdi-checkbox-outline';
      if (this.employeeSelected) return 'mdi-minus-box-outline';
      return 'mdi-checkbox-blank-outline';
    },
    disableConfirm() {
      return (
        this.selectedLabels.length == 0 &&
        this.selectedEmployees.length == 0 &&
        this.publish == null &&
        this.selectedGroup == null
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 249 / 2;
    },
    showPublishWarning() {
      return (
        this.publish == 0 &&
        this.documents.some(
          (d) => d.hasLabelOrSchedule || d.assignmentCount > 0,
        )
      );
    },
  },
  methods: {
    getData() {
      if (!this.loaded) {
        const params = {
          loaderText: 'Loading...',
        };
        const url = 'get-data-to-manage-private-documents?format=json';

        this.$axios
          .post(url, params)
          .then((response) => {
            this.labels = response.data.labels;
            this.employees = response.data.employees;
            this.loaded = true;
          })
          .catch((error) => error);
      }
    },
    confirm() {
      const params = {
        loaderText: 'Saving...',
        docIds: this.documents.map((item) => item.id),
        sendEmail: this.sendEmail,
      };

      if (this.selectedGroup != null) {
        params.group = this.selectedGroup;
      }

      if (this.selectedLabels.length > 0) {
        params.labels = this.selectedLabels;
      }

      if (this.selectedEmployees.length > 0) {
        params.employees = this.selectedEmployees;
        params.sendEmail = this.sendEmail;
      }

      if (this.publish != null) {
        params.publish = this.publish;
      }

      const url = 'private-documents-mass-management?format=json';

      this.$axios.post(url, params).then((response) => {
        if (response.data.length) {
          this.handleUploadWarning(response.data);
        }
        this.$emit('confirmed');
        this.close();
      });
    },
    /**
     * Handles the upload warning response due to fail to convert empty spreadsheet to PDF.
     * Displays a warning message with the list of files.
     *
     * @param {Array} responseData - The response data containing the list of document ids that failed to convert.
     */
    handleUploadWarning(responseData) {
      const documentNames = this.documents
        .filter((doc) => responseData.includes(doc.id))
        .map((doc) => doc.name);
      this.$root.showMessage(
        'Warning',
        'Changes for the following files are not saved due to failed to convert to PDF. <br>' +
          documentNames.join('<br>'),
        () => {},
        null,
        'Ok',
        '',
      );
    },
    deleteAll() {
      const confirm = () => {
        const params = {
          loaderText: 'Deleting...',
          docIds: this.documents.map((item) => item.id),
        };
        const url = 'delete-private-library-documents?format=json';

        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('confirmed');
            this.close();
          })
          .catch((error) => error);
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete these files?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    unselect(doc) {
      this.$emit('onUnselect', doc);
      if (this.documents.length == 0) {
        this.close();
      }
    },
    cancel() {
      this.$emit('unselectAll');
      this.close();
    },
    close() {
      this.selectedGroup = null;
      this.selectedLabels = [];
      this.selectedEmployees = [];
      this.dialog = false;
      this.sendEmail = 1;
      this.publish = null;
    },
    updatePublish(val) {
      this.publish = val;
      if (
        !val &&
        (this.selectedLabels.length || this.selectedEmployees.length)
      ) {
        this.selectedEmployees = [];
        this.selectedLabels = [];
      }
    },
  },
};
</script>

<style>
/* override publish button disabled style */
#publish-btn-toggle .v-btn--disabled.publish-btn {
  background-color: #3564ac !important;
  color: white !important;
}

#publish-btn-toggle .v-btn--disabled.publish-btn .publish-icon {
  color: #4caf50 !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>

<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="msaBlue white--text"> Mobile Settings </v-card-title>
      <v-card-text class="pa-4">
        <v-row :style="{ 'border-bottom': '1px black dashed' }">
          <v-col> GENERAL </v-col>
        </v-row>

        <v-row
          v-for="(setting, settingKey) in generalSettings"
          :key="setting.displayName"
          align="center"
        >
          <v-col class="grow ml-4">
            {{ setting.displayName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ setting.toolTip }}
            </v-tooltip>
          </v-col>
          <v-col class="shrink">
            <v-switch
              v-model="setting.isEnabled"
              :false-value="0"
              :true-value="1"
              @change="updateCompanySetting(setting, settingKey)"
              class="mt-0"
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-row :style="{ 'border-bottom': '1px black dashed' }">
          <v-col> SYNCHRONIZATION OPTIMIZATION </v-col>
        </v-row>

        <v-row align="center">
          <v-col class="grow ml-4">
            Initial Synchronization
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              This setting will only affect users new synchronization on
              install.
            </v-tooltip>
          </v-col>
          <v-col class="shrink" style="min-width: 175px">
            <v-form v-model="syncWindowValid">
              <v-tooltip bottom>
                Number of days must be {{ initialSyncWindow.range.min }} to
                {{ initialSyncWindow.range.max }}
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="settings.initialSyncWindow"
                    v-on="on"
                    :rules="[
                      rules.isValidRemoveWindow(
                        initialSyncWindow.range,
                        'initialSyncWindow',
                      ),
                    ]"
                    class="mt-4"
                    dense
                    outlined
                    hide-details
                  />
                </template>
              </v-tooltip>
            </v-form>
          </v-col>
        </v-row>

        <v-row :style="{ 'border-bottom': '1px black dashed' }" class="mb-4">
          <v-col>
            DEVICE STORAGE
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              Settings that specify the number of days items will be stored on
              the mobile device.
            </v-tooltip>
          </v-col>
        </v-row>

        <v-form v-model="storageValid">
          <v-row
            v-for="(setting, settingKey) of deviceStorageSettings"
            :key="setting.displayName"
            align="center"
            class="mb-2"
          >
            <v-col class="grow ml-4">
              Select how many days
              <strong>{{ setting.displayName }}</strong> are kept on the mobile
              devices
            </v-col>

            <v-col
              v-if="settingKey == 'assignedDocumentMobileRemoveWindow'"
              class="shrink"
            >
              <v-tooltip bottom>
                {{ setting.hint }}
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-switch
                      v-model="isDocRemoveWindowEnabled"
                      :false-value="0"
                      :true-value="1"
                      @change="correctAssignedDocumentRemoveWindow($event)"
                      class="mt-2"
                      hide-details
                    />
                  </span>
                </template>
              </v-tooltip>
            </v-col>

            <v-col class="shrink" style="min-width: 175px">
              <v-tooltip
                :disabled="isDeviceStorageSettingDisabled(setting)"
                bottom
              >
                Number of days must be
                {{ setting.range.min }} to
                {{ setting.range.max }}
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="settings[settingKey]"
                    v-on="on"
                    :disabled="isDeviceStorageSettingDisabled(setting)"
                    hide-details
                    :rules="[
                      rules.isValidRemoveWindow(
                        { ...setting.range },
                        settingKey,
                      ),
                    ]"
                    class="mt-4"
                    dense
                    maxLength="3"
                    outlined
                  />
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col align="right">
            <v-btn
              :disabled="disableSave"
              @click="updateDeviceStorageSettings"
              class="white--text msaBlue"
            >
              save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'MobileSettingsPage',
  data() {
    return {
      /**
       * Company mobile settings that haven't been applied yet.
       */
      settings: {},
      generalSettings: {
        is1LifeMobileEnabled: {
          isEnabled: 0,
          displayName: 'Turn on mobile access',
          toolTip:
            'When enabled, users will have access to log into the new mobile app.',
        },
        hideQuickNoteHazard: {
          isEnabled: 0,
          displayName: 'Hide Quick Note Hazard(s) Template',
          toolTip:
            'If enabled, Quick Note Hazard(s) will be hidden from the form section.',
        },
      },
      initialSyncWindow: {
        range: {
          min: 1,
          max: 30,
        },
        value: 30,
      },
      deviceStorageSettings: {
        assignedFormMobileRemoveWindow: {
          displayName: 'Assigned Forms',
          range: {
            min: 30,
            max: 90,
          },
          removeWindow: 90,
        },
        formMobileRemoveWindow: {
          displayName: 'Submitted & Reviewed Forms',
          range: {
            min: 1,
            max: 180,
          },
          removeWindow: 180,
        },
        finalizedFormMobileRemoveWindow: {
          displayName: 'Finalized Forms',
          range: {
            min: 1,
            max: 30,
          },
          removeWindow: 30,
        },
        assignedDocumentMobileRemoveWindow: {
          displayName: 'Assigned Documents',
          hint: 'Allow Assigned Documents to be removed',
          range: {
            min: 30,
            max: 365,
          },
          removeWindow: 365,
        },
        submittedDocumentMobileRemoveWindow: {
          displayName: 'Submitted Documents',
          range: {
            min: 1,
            max: 30,
          },
          removeWindow: 30,
        },
      },
      rules: {
        isValidRemoveWindow: ({ min, max }) => {
          // Also returns message if it's not a number or an empty string with out implicitly checking
          return (value) => {
            const numberValue = Number(value); // returns NaN if not a number

            // NaN is a falsy value
            if (!numberValue) {
              return 'Enter a number.';
            }

            if (value >= min && value <= max && Number.isInteger(numberValue)) {
              return true;
            }

            return 'Invalid number of days.';
          };
        },
      },
      isDocRemoveWindowEnabled: 0, // For assignedDocumentMobileRemoveWindow toggle and to compare against settings
      storageValid: true,
      syncWindowValid: true,
    };
  },
  computed: {
    isDeviceStorageSettingChanges() {
      // Check settings with a remove window
      for (const key in this.deviceStorageSettings) {
        if (
          this.deviceStorageSettings[key].removeWindow != this.settings[key]
        ) {
          return true;
        }
      }

      // Check if the toggle has changed
      if (
        this.settings['isAssignedDocumentMobileRemoveWindowEnabled'] !=
        this.isDocRemoveWindowEnabled
      ) {
        return true;
      }

      return false;
    },
    isSyncWindowChanged() {
      return this.initialSyncWindow.value != this.settings.initialSyncWindow;
    },
    disableSave() {
      if (!this.storageValid || !this.syncWindowValid) {
        return true;
      }

      if (!this.isDeviceStorageSettingChanges && !this.isSyncWindowChanged) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.getCompanyMobileSettings();
  },
  methods: {
    /**
     * Whether or not the user has modified any device storage settings
     * @return {Boolean} true if settings have been modified
     */
    updateDeviceStorageSettings() {
      let payload = JSON.parse(JSON.stringify(this.settings));
      // Add toggle value to payload
      payload['isAssignedDocumentMobileRemoveWindowEnabled'] =
        this.isDocRemoveWindowEnabled;

      const url = 'update-mobile-device-storage-settings?format=json';
      const params = {
        loaderText: 'Loading...',
        payload: payload,
      };

      this.$axios.post(url, params).then(() => {
        // Refresh settings
        this.getCompanyMobileSettings();
      });
    },
    /**
     * Corrects the 'assignedDocumentMobileRemoveWindow' setting when toggled.
     *
     * Prevents uneditable values from causing validation errors when the toggle is disabled.
     * If the toggle is disabled, resets the 'assignedDocumentMobileRemoveWindow' setting to its initial value
     * to avoid an 'Invalid Setting.' error.
     *
     * @param {boolean} isEnabling - Indicates if the toggle is being enabled.
     */
    correctAssignedDocumentRemoveWindow(isEnabling) {
      if (!isEnabling) {
        this.settings['assignedDocumentMobileRemoveWindow'] =
          this.deviceStorageSettings[
            'assignedDocumentMobileRemoveWindow'
          ].removeWindow; // MUST SET TO INITIAL VALUE from CompanySettings table or will return 'Invalid Setting.' error because it'll think you're editing the value even though it is disabled.
      }
    },
    /**
     * Forces a number to fit in a range.
     * @return {Number} Input number, changed if it didn't fall inside the range
     */
    constrainToRange({ removeWindow, range }) {
      if (removeWindow < range.min || removeWindow > range.max) {
        return removeWindow < range.min ? range.min : range.max;
      }
      return removeWindow;
    },
    getCompanyMobileSettings() {
      const url = 'get-company-mobile-settings?format=json';
      const params = {
        loaderText: 'Loading...',
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          // Needed to be loaded before building to avoid setting[key] undefined errors in computed properties
          this.settings = response.data;

          // Set separately from deviceStorageSettings due to v-for looping through deviceStorageSettings in DEVICE STORAGE section
          this.isDocRemoveWindowEnabled =
            this.settings['isAssignedDocumentMobileRemoveWindowEnabled'];
        })
        .then(() => {
          this.buildGeneralSettings();
          this.buildDeviceStorageSettings();
          this.initialSyncWindow.value = this.settings.initialSyncWindow;
        });
    },
    isDeviceStorageSettingDisabled(setting) {
      // Only conditionally disable the assignedDocumentMobileRemoveWindow setting
      if (setting.displayName == 'Assigned Documents') {
        return !this.isDocRemoveWindowEnabled;
      }
      // All other settings are always enabled
      return false;
    },
    updateCompanySetting(setting, settingKey) {
      const url = 'update-company-setting?format=json';
      const params = {
        setting: settingKey,
        loaderText: 'Saving...',
        snackText: 'Mobile setting updated.',
        value: setting.isEnabled,
      };

      this.$axios.post(url, params);
    },
    buildGeneralSettings() {
      for (const setting in this.generalSettings) {
        this.generalSettings[setting].isEnabled = this.settings[setting];
      }
    },
    buildDeviceStorageSettings() {
      for (const setting in this.deviceStorageSettings) {
        this.deviceStorageSettings[setting].removeWindow =
          this.settings[setting];
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Filter </v-col>
            <v-col class="shrink pr-6">
              <v-btn
                icon
                @click="
                  dialog = false;
                  thisFilters = { ...filters };
                "
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Template Name </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.search"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
              data-testid="form-template-name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Project </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.projectIds"
              multiple
              label="Select project(s)"
              hide-details
              :items="projects"
              clearable
              @change="thisFilters.projectIds = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold">
            Completed Date
          </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="dateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.dateFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.dateFrom"
                no-title
                @input="dateFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="dateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.dateTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.dateTo"
                no-title
                @input="dateToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense class="pb-2">
          <v-col cols="12">
            <v-checkbox
              v-model="thisFilters.includeUnpublished"
              :true-value="1"
              :false-value="0"
              outlined
              dense
              hide-details
            >
              <template v-slot:label>
                <span class="font-weight-bold"
                  >Include Unpublished Templates</span
                >
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="clear()"> clear </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn class="white msaBlue--text" @click="apply()">
                apply
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormReportsFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: { ...this.$constants.FORM_REPORTS_FEFAULT_FILTERS },
      projects: [],
      dateFromMenu: false,
      dateToMenu: false,
      loaded: false,
    };
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.FORM_REPORTS_FEFAULT_FILTERS)
      );
    },
  },
  watch: {
    dialog(val) {
      if (val && !this.loaded) {
        this.getDataForFilter();
      }
    },
  },

  methods: {
    getDataForFilter() {
      const params = {
        loaderText: 'loading...',
      };
      const url = 'get-projects-for-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        this.projects = response.data;
        this.loaded = true;
      });
    },
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.FORM_INSTANCE_DEFAULT_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.search == null) {
        this.thisFilters.search = '';
      }

      if (this.thisFilters.dateFrom == null) {
        this.thisFilters.dateFrom = '';
      }

      if (this.thisFilters.dateTo == null) {
        this.thisFilters.dateTo = '';
      }
    },
    setFilters() {
      this.thisFilters = { ...this.filters };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>

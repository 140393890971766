<template>
  <v-container fluid>
    <FormReportDialog ref="reportDialog" />
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="onSearch"
          ref="searchBar"
          searchLabel="Search by template name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <FormReportsFilter
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text py-5">
            Templates
            <v-badge
              :content="itemsCount"
              :value="itemsCount"
              bordered
              class="ml-2"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="itemsCount"
            must-sort
          >
            <template v-slot:[`item.groupName`]="{ item }">
              <span>
                {{ item.groupName }}
              </span>
            </template>
            <template v-slot:[`item.templateName`]="{ item }">
              <span>
                {{ item.templateName }}
              </span>
            </template>
            <template v-slot:[`item.isPublished`]="{ item }">
              <span>
                <v-icon v-if="item.isPublished" color="green"> mdi-eye </v-icon>
                <v-icon v-else color="orange"> mdi-eye </v-icon>
              </span>
            </template>
            <template v-slot:[`item.formCount`]="{ item }">
              <span>
                {{ item.formCount }}
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.formCount != 0"
                color="primary"
                small
                dark
                class="mr-2"
                @click="showDialog(item)"
              >
                Export
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormReportsFilter from '@/components/Filters/FormReportsFilter.vue';
import SearchBar from '../components/SearchBar.vue';
import FormReportDialog from '@/components/FormReportDialog.vue';
export default {
  name: 'FormsReportPage',
  components: {
    SearchBar,
    FormReportsFilter,
    FormReportDialog,
  },
  filters: {
    truncate(text, length = 100) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      itemsCount: 0,
      items: [],
      filters: { ...this.$constants.FORM_REPORTS_FEFAULT_FILTERS },
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['templateName'],
        sortDesc: [false],
      },
      headers: [
        {
          text: 'Group',
          value: 'groupName',
          width: '20%',
        },
        {
          text: 'Template',
          value: 'templateName',
        },
        {
          text: 'Status',
          value: 'isPublished',
          width: '100',
        },
        {
          text: 'Form Count',
          value: 'formCount',
          sortable: true,
          width: '120',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          width: '120',
        },
      ],
    };
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.search != '') {
        this.$refs.searchBar.setSearch(this.filters.search);
      }
      this.$refs.filters.setFilters();
      this.init();
    });
  },
  methods: {
    showDialog(item) {
      this.$refs.reportDialog.open(this.filters, item);
    },
    applyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;
      this.updateUrl();
    },
    onSearch(data) {
      this.filters.search = data;
      this.options.page = 1;
      this.updateUrl();
    },
    async getItems() {
      const url = 'get-form-templates-for-form-report?format=json';
      const params = {
        loaderText: 'Loading...',
        options: this.options,
        filters: this.filters,
      };
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },

    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(
        query,
        'templateName',
        false,
      );
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.FORM_REPORTS_FEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'templateName',
        false,
      );
      params = { ...params, ...options };

      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    async init() {
      await this.getItems();
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="isNewElement">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
            data-testid="add-new-question"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add New Question</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ modalTitle }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="edited.elementName"
              outlined
              dense
              label="Question"
              :rules="required"
              maxlength="500"
              counter
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="edited.typeId"
              outlined
              dense
              label="Question Type"
              :items="elementTypes"
              item-value="id"
              item-text="name"
              :rules="required"
              chips
              @change="checkTypeChange"
            >
              <template v-slot:selection="{ item }">
                <v-chip small class="msaBlue white--text">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              :value="edited.sectionId"
              label="Section"
              :items="sections"
              item-text="sectionName"
              item-value="sectionId"
              :rules="required"
              @change="edited.sectionId = $event"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="edited.description"
              outlined
              dense
              label="Description"
              maxlength="1000"
              counter
            >
            </v-textarea>
          </v-col>

          <v-col v-if="isDropDown" cols="12">
            <draggable handle=".handle" :list="edited.dropDownOptions">
              <v-list-item
                v-for="(option, index) in edited.dropDownOptions"
                :key="index"
              >
                <v-list-item-title>
                  <v-row align="center" dense>
                    <v-col class="shrink mb-7">
                      <v-btn class="handle" :style="{ cursor: 'grab' }" icon>
                        <v-icon>mdi-swap-vertical</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="grow">
                      <v-textarea
                        v-model.trim="option.optionText"
                        counter
                        maxlength="500"
                        outlined
                        dense
                        placeholder="Option"
                        auto-grow
                        rows="1"
                      />
                    </v-col>
                    <v-col class="shrink mb-7">
                      <v-btn
                        @click="removeDropDownOption(index)"
                        icon
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </draggable>
            <v-row>
              <v-col align="center" cols="12">
                <v-btn outlined width="100%" @click="addDropDownOption">
                  ADD OPTION
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="isScoreQuestion">
            <v-text-field
              outlined
              dense
              placeholder="Score"
              hide-details="auto"
              v-model="edited.score"
              :rules="scoreRule"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" v-if="isScoreQuestion">
            <v-checkbox
              v-model="edited.correctiveActionRequired"
              dense
              hide-details
              label="Corrective action required when the answer is not satisfactory."
            >
            </v-checkbox>
          </v-col>

          <v-col cols="12" v-if="isFileQuestion">
            <v-text-field
              outlined
              dense
              :placeholder="uploadPlaceHolder"
              hide-details
              v-model="edited.filename"
              readonly
            >
              <template v-slot:append-outer>
                <v-btn
                  class="msaBlue white--text mt-n2"
                  @click="$refs.uploader.click()"
                >
                  {{ uploadButtonText }}
                </v-btn>
                <input
                  type="file"
                  ref="uploader"
                  class="d-none"
                  :accept="allowedFileExtensions"
                  @change="onFileSelected"
                />
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" v-if="showInputRequired">
            <v-checkbox
              v-model="edited.inputRequired"
              dense
              hide-details
              label="Answer Required"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col v-if="isDropDown" cols="12" align="right" class="caption">
            *You need at least 2 options to save the question.
          </v-col>
          <v-col align="right">
            <v-btn text color="red" @click="close()"> CANCEL </v-btn>
            <v-btn
              class="msaBlue white--text"
              :disabled="isSaveDisabled"
              @click="save()"
            >
              SAVE
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'FormTemplateElementDetail',
  components: {
    Draggable,
  },
  props: {
    section: {
      type: Object,
      default() {
        return {
          sectionId: 0,
          isOrdering: false,
          sectionName: '',
        };
      },
    },
    element: {
      type: Object,
      default() {
        return {
          elementId: 0,
          elementName: '',
          elementPosition: 0,
          description: '',
          formTemplateId: this.$store.getters.selectedFormTemplate.id,
          formTemplateGroupId: this.$store.getters.selectedFormTemplate.groupId,
          inputRequired: true,
          isOrdering: false,
          sectionId: this.section.sectionId,
          typeId: null,
          dropDownOptions: [],
          filename: '',
          score: '',
          correctiveActionRequired: '',
        };
      },
    },
    elementTypes: {
      type: Array,
    },
    sections: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      edited: {},
      required: [(v) => !!v || 'This field is required'],
      minDropDownOptions: 2,
      file: '',
      scoreRule: [
        (v) => this.isValidScore(v) || 'Score must be between 1-100.',
      ],
    };
  },
  computed: {
    isNewElement() {
      return this.element.elementId == 0;
    },
    modalTitle() {
      return this.isNewElement ? 'Add New Question' : 'Edit Question';
    },
    isDropDown() {
      return (
        this.edited?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE ||
        this.edited?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI
      );
    },
    isOriginalDropDown() {
      return (
        this.computedDefaultElement?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE ||
        this.computedDefaultElement?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI
      );
    },
    isSaveDisabled() {
      const isRequiredFieldsMissing =
        !this.edited.typeId ||
        !this.edited.sectionId ||
        !this.edited?.elementName ||
        this.edited.elementName.trim() == '';
      if (isRequiredFieldsMissing) {
        return true;
      }

      if (this.isDropDown && !this.isValidDropDownQuestion) {
        return true;
      }

      if (this.isFileQuestion && !this.edited.filename) {
        return true;
      }

      if (this.isScoreQuestion && !this.isValidScore(this.edited.score)) {
        return true;
      }

      const hasChanges =
        this.nameChanged ||
        this.descriptionChanged ||
        this.inputRequiredChanged ||
        this.sectionChanged ||
        this.questionTypeChanged ||
        this.dropDownOptionsChanged ||
        this.fileChanged ||
        this.scoreChanged;

      if (!hasChanges) {
        return true;
      }

      return false;
    },
    nameChanged() {
      return (
        this.edited.elementName.trim() !=
        this.computedDefaultElement.elementName
      );
    },
    descriptionChanged() {
      return (
        this.edited.description.trim() !=
        this.computedDefaultElement.description
      );
    },
    inputRequiredChanged() {
      return (
        this.showInputRequired &&
        this.edited.inputRequired != this.computedDefaultElement.inputRequired
      );
    },
    sectionChanged() {
      return this.edited.sectionId != this.computedDefaultElement.sectionId;
    },
    questionTypeChanged() {
      return this.edited.typeId != this.computedDefaultElement.typeId;
    },
    dropDownOptionsChanged() {
      return (
        this.isDropDown &&
        JSON.stringify(this.edited.dropDownOptions) !=
          JSON.stringify(this.computedDefaultElement.dropDownOptions)
      );
    },
    fileChanged() {
      return (
        this.isFileQuestion &&
        (this.file ||
          this.edited.filename != this.computedDefaultElement.filename)
      );
    },
    scoreChanged() {
      return (
        this.isScoreQuestion &&
        (this.edited.score != this.computedDefaultElement.score ||
          this.edited.correctiveActionRequired !=
            this.computedDefaultElement.correctiveActionRequired)
      );
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
    computedDefaultElement() {
      return this.isInternalAdmin
        ? {
            ...this.element,
            publicFormTemplateId: this.$store.getters.selectedFormTemplate.id,
            publicFormTemplateGroupId:
              this.$store.getters.selectedFormTemplate.groupId,
            sectionId: this.section.sectionId,
          }
        : {
            ...this.element,
            formTemplateId: this.$store.getters.selectedFormTemplate.id,
            formTemplateGroupId:
              this.$store.getters.selectedFormTemplate.groupId,
            sectionId: this.section.sectionId,
          };
    },
    isValidDropDownQuestion() {
      if (this.edited.dropDownOptions.length < this.minDropDownOptions) {
        return false;
      }

      const hasEmptyOptions = this.edited.dropDownOptions.some(
        (option) => !option.optionText.trim(),
      );
      if (hasEmptyOptions) {
        return false;
      }

      return true;
    },
    showInputRequired() {
      return (
        this.edited.typeId != this.$constants.FORM_ELEMENT_TYPES.CHECKBOX &&
        this.edited.typeId != this.$constants.FORM_ELEMENT_TYPES.INSTRUCTIONS &&
        this.edited.typeId != this.$constants.FORM_ELEMENT_TYPES.IMAGE &&
        this.edited.typeId != this.$constants.FORM_ELEMENT_TYPES.SCORE
      );
    },
    isFileQuestion() {
      return (
        this.edited.typeId == this.$constants.FORM_ELEMENT_TYPES.PDF ||
        this.edited.typeId == this.$constants.FORM_ELEMENT_TYPES.IMAGE
      );
    },
    isScoreQuestion() {
      return this.edited.typeId == this.$constants.FORM_ELEMENT_TYPES.SCORE;
    },
    uploadPlaceHolder() {
      return this.edited.typeId == this.$constants.FORM_ELEMENT_TYPES.PDF
        ? 'PDF'
        : 'Image';
    },
    uploadButtonText() {
      if (this.edited.filename) {
        return 'Replace';
      }
      return 'Upload';
    },
    allowedFileExtensions() {
      return this.edited.typeId == this.$constants.FORM_ELEMENT_TYPES.PDF
        ? '.pdf'
        : '.jpg, .jpeg, .png';
    },
    isOriginalFileQuestion() {
      return (
        this.computedDefaultElement?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.PDF ||
        this.computedDefaultElement?.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.IMAGE
      );
    },
  },
  methods: {
    isValidScore(val) {
      return /^0*(?:[1-9]\d?|100)$/.test(val);
    },
    addDropDownOption() {
      this.edited.dropDownOptions.push({
        optionText: '',
      });
    },
    removeDropDownOption(index) {
      this.edited.dropDownOptions.splice(index, 1);
    },
    popData() {
      this.edited = JSON.parse(JSON.stringify(this.computedDefaultElement));
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      const params = new FormData();
      params.append('file', this.file);
      params.append('element', JSON.stringify(this.edited));
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Saving...');

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const url = this.isNewElement
        ? this.isInternalAdmin
          ? 'create-public-form-template-element'
          : 'create-form-template-element'
        : this.isInternalAdmin
        ? 'edit-public-form-template-element'
        : 'edit-form-template-element';
      this.$axios
        .post(url, params, headers)
        .then((response) => {
          if (this.isNewElement) {
            this.edited.elementId = response.data;
          }
          if (this.edited.sectionId != this.computedDefaultElement.sectionId) {
            this.$emit('sectionChanged');
          }
          this.$emit('saved', this.edited);
          this.file = '';
          this.close();
        })
        .catch((error) => error);
    },
    checkTypeChange() {
      if (this.isDropDown) {
        this.edited.dropDownOptions = JSON.parse(
          JSON.stringify(this.computedDefaultElement.dropDownOptions),
        );
      } else {
        this.edited.dropDownOptions = [];
      }

      if (
        (this.isFileQuestion && this.questionTypeChanged) ||
        !this.isFileQuestion
      ) {
        this.edited.filename = '';
      } else {
        this.edited.filename = this.computedDefaultElement.filename;
      }

      if (this.isScoreQuestion) {
        this.edited.inputRequired = 1;
        this.edited.score = this.computedDefaultElement.score;
        if (this.questionTypeChanged) {
          this.edited.correctiveActionRequired = 0;
        } else {
          this.edited.correctiveActionRequired =
            this.computedDefaultElement.correctiveActionRequired;
        }
      } else {
        this.edited.score = '';
        this.edited.correctiveActionRequired = '';
      }
    },
    async onFileSelected(event) {
      // this handles when the user cancels the file selection
      if (!event.target.files.length) {
        return;
      }

      let file = event.target.files[0];
      this.$root.toggleLoaderText(true);
      let acceptedMimeTypes = this.$constants.ACCEPTED_MIME_TYPES.PDF;
      let maxFileSize = this.$constants.MAX_FILE_SIZE['30MB'];
      if (file.type != 'application/pdf') {
        acceptedMimeTypes = this.$constants.ACCEPTED_MIME_TYPES.IMAGE;
        maxFileSize = this.$constants.MAX_FILE_SIZE['5MB'];
      }
      try {
        this.file = await this.$helpers.processFile(
          file,
          acceptedMimeTypes,
          true,
          maxFileSize,
        );
        this.edited.filename = file.name;
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
      }
      this.$root.toggleLoaderText(false);
    },
  },
};
</script>

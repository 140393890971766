<template>
  <v-card>
    <v-card-title class="msaGrey">{{ section.sectionName }}</v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="section.elements"
        disable-pagination
        hide-default-footer
        disable-filtering
        mobile-breakpoint="960"
        :disable-sort="isMobile"
        :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
      >
        <template v-slot:[`item`]="{ item, index }">
          <tr v-if="!isMobile">
            <td align="center" :style="inputRequiredStyle(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    {{ typeIcon(item.typeId) }}
                  </v-icon>
                </template>
                <span> {{ typeName(item.typeId) }} </span>
              </v-tooltip>
            </td>
            <td>
              <v-row no-gutters>
                <v-col cols="12" class="font-weight-medium">
                  {{ item.elementName }}
                </v-col>
                <v-col class="text-caption">
                  {{ item.description }}
                </v-col>
              </v-row>
            </td>
            <td align="center">
              <span v-if="!isOrdering">
                <FormTemplateElementDetail
                  :section="section"
                  :element="item"
                  :sections="sections"
                  :elementTypes="elementTypes"
                  @saved="$emit('elementSaved', $event)"
                  @sectionChanged="removeElement(index, section)"
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      small
                      v-on="on"
                      color="msaBlue"
                      @click="
                        duplicateElement(
                          item.elementId,
                          item.elementName.length,
                        )
                      "
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span> Duplicate </span>
                </v-tooltip>
                <v-tooltip bottom v-if="!isSearching">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-blur
                      text
                      small
                      v-on="on"
                      @click="
                        $emit('isOrdering');
                        item.isOrdering = 1;
                      "
                    >
                      <v-icon> mdi-swap-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>Change Position</span>
                </v-tooltip>
                <FormTemplateElementDeletion
                  :elementId="item.elementId"
                  @deleted="removeElement(index, section)"
                />
              </span>
              <span v-if="isOrdering && !item.isOrdering"></span>
              <span v-if="item.isOrdering" class="pl-0 pr-1" align="center">
                <ChangeOrderButtonGroup
                  :currentIndex="index"
                  :maxIndex="section.elements.length - 1"
                  @updatePositions="
                    $emit('updateElementPositions');
                    item.isOrdering = 0;
                  "
                  @onOrder="$emit('onOrder', $event)"
                />
              </span>
            </td>
          </tr>
          <v-card v-if="isMobile">
            <v-card-text>
              <v-row>
                <v-col cols="3"> Question </v-col>
                <v-col align="right">
                  {{ item.elementName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> Type </v-col>
                <v-col align="right">
                  {{ typeName(item.typeId) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> Input Required </v-col>
                <v-col align="right">
                  {{ item.inputRequired ? 'Yes' : 'No' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> Actions </v-col>
                <v-col align="right">
                  <span v-if="!isOrdering">
                    <FormTemplateElementDetail
                      :section="section"
                      :element="item"
                      :sections="sections"
                      :elementTypes="elementTypes"
                      @saved="$emit('elementSaved', $event)"
                      @sectionChanged="removeElement(index, section)"
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          small
                          v-on="on"
                          color="msaBlue"
                          @click="
                            duplicateElement(
                              item.elementId,
                              item.elementName.length,
                            )
                          "
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span> Duplicate </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!isSearching">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-on="on"
                          @click="
                            $emit('isOrdering');
                            item.isOrdering = 1;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <FormTemplateElementDeletion
                      :elementId="item.elementId"
                      @deleted="removeElement(index, section)"
                    />
                  </span>
                  <span v-if="isOrdering && !item.isOrdering"></span>
                  <span v-if="item.isOrdering" class="pl-0 pr-1" align="center">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="section.elements.length - 1"
                      @updatePositions="
                        $emit('updateElementPositions');
                        item.isOrdering = 0;
                      "
                      @onOrder="$emit('onOrder', $event)"
                    />
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="!isOrdering" :key="reloadElementDetail">
      <v-spacer></v-spacer>
      <FormTemplateElementDetail
        :section="section"
        :sections="sections"
        :elementTypes="elementTypes"
        @saved="$emit('elementSaved', $event)"
        @close="reloadElementDetail = !reloadElementDetail"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import FormTemplateElementDeletion from '@/components/FormTemplates/FormTemplateElementDeletion.vue';
import FormTemplateElementDetail from '@/components/FormTemplates/FormTemplateElementDetail.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
export default {
  name: 'FormTemplateElements',
  props: {
    section: {
      type: Object,
    },
    isOrdering: {
      type: Number,
    },
    elementTypes: {
      type: Array,
    },
    sections: {
      type: Array,
    },
    isSearching: {
      type: Boolean,
    },
  },
  components: {
    FormTemplateElementDetail,
    FormTemplateElementDeletion,
    ChangeOrderButtonGroup,
  },
  computed: {
    typeIcon() {
      return (typeId) => {
        switch (typeId) {
          case this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM:
            return 'mdi-magnify';
          case this.$constants.FORM_ELEMENT_TYPES.TEXT_FIELD:
            return 'mdi-pencil';
          case this.$constants.FORM_ELEMENT_TYPES.CHECKBOX:
            return 'mdi-check';
          case this.$constants.FORM_ELEMENT_TYPES.YES_NO:
            return 'mdi-help-circle-outline';
          case this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS:
            return 'mdi-alert-outline';
          case this.$constants.FORM_ELEMENT_TYPES.INSTRUCTIONS:
            return 'mdi-book-open-variant';
          case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE:
            return 'mdi-form-dropdown';
          case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI:
            return 'mdi-form-dropdown';
          case this.$constants.FORM_ELEMENT_TYPES.DATE:
            return 'mdi-calendar-month-outline';
          case this.$constants.FORM_ELEMENT_TYPES.TIME:
            return 'mdi-clock-time-three-outline';
          case this.$constants.FORM_ELEMENT_TYPES.PDF:
            return 'mdi-file-pdf-box';
          case this.$constants.FORM_ELEMENT_TYPES.IMAGE:
            return 'mdi-image-outline';
          case this.$constants.FORM_ELEMENT_TYPES.SCORE:
            return 'mdi-percent-box-outline';
          default:
            break;
        }
      };
    },
    typeName() {
      return (typeId) => {
        const type = this.elementTypes.find((t) => t.id == typeId);

        // This makes sure the page doesn't break if the company has form templates with drop down questions while the new mobile access isn't enabled
        if (!type) {
          return '';
        }
        return type.name;
      };
    },
    inputRequiredStyle() {
      return (element) => {
        if (
          element.typeId == this.$constants.FORM_ELEMENT_TYPES.CHECKBOX ||
          element.typeId == this.$constants.FORM_ELEMENT_TYPES.INSTRUCTIONS ||
          element.typeId == this.$constants.FORM_ELEMENT_TYPES.IMAGE ||
          !element.inputRequired
        ) {
          return { 'padding-left': '20px' };
        } else {
          return { 'border-left': '4px red solid' };
        }
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Type',
          value: 'typeId',
          class: 'lightGrey',
          sortable: false,
          align: 'center',
          width: '60px',
        },
        {
          text: 'Name',
          value: 'elementName',
          class: 'lightGrey',
        },

        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '335px',
        },
      ],
      reloadElementDetail: false,
    };
  },
  methods: {
    removeElement(index, section) {
      section.elements.splice(index, 1);
    },
    duplicateElement(elementId, nameLength) {
      const maxTemplateName = 493; // 500 MINUS ' (Copy)'.length
      if (nameLength > maxTemplateName) {
        this.$root.showMessage(
          'Warning',
          "Duplicating this question will remove characters form the end of the name to make room for ' (Copy) '. Do you want to proceed?",
          () => this.duplicateElement(elementId, 0),
          () => {},
          'CONFIRM',
          'CANCEL',
        );

        return;
      }
      const params = {
        loaderText: 'Loading...',
        id: elementId,
      };
      const url = this.isInternalAdmin
        ? 'duplicate-public-form-template-element?format=json'
        : 'duplicate-form-template-element?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.$emit('onDuplicate', response.data);
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>

<style></style>

<template>
  <v-container fluid>
    <v-row
      dense
      @click="$emit('showElementDetail')"
      :style="{ cursor: 'pointer' }"
    >
      <v-col cols="8" class="black--text mr-auto">
        <div v-if="caCounts.total > 0">
          <span
            v-if="caCounts.unresolved == 0"
            class="green white--text pa-1"
            :style="{ 'border-radius': '4px' }"
          >
            RESOLVED
          </span>
          <span
            v-else
            class="red white--text pa-1"
            :style="{ 'border-radius': '4px' }"
          >
            UNRESOLVED
          </span>
          <span class="ml-4" v-if="caCounts.high > 0">
            <v-icon color="red">mdi-alert</v-icon>
            {{ caCounts.high }} High
          </span>
          <span class="ml-4" v-if="caCounts.medium > 0">
            <v-icon color="orange">mdi-alert</v-icon>
            {{ caCounts.medium }} Medium
          </span>
          <span class="ml-4" v-if="caCounts.low > 0">
            <v-icon color="green">mdi-alert</v-icon>
            {{ caCounts.low }} Low
          </span>
          <span class="ml-4" v-if="caCounts.unranked > 0">
            <v-icon>mdi-alert</v-icon>
            {{ caCounts.unranked }}
          </span>
        </div>
        <div class="font-weight-bold black--text">
          {{ element.name }}
        </div>
      </v-col>
      <v-col cols="4" align="right">
        <v-badge inline :value="caCounts.total > 0" :content="caCounts.total">
          <v-icon :color="caCounts.total > 0 ? 'msaBlue' : 'grey'">
            mdi-alert-outline
          </v-icon>
        </v-badge>
        <v-badge inline :value="imageCount > 0" :content="imageCount">
          <v-icon :color="imageCount > 0 ? 'msaBlue' : 'grey'" class="ml-4">
            mdi-image-outline
          </v-icon>
        </v-badge>
        <v-badge inline :value="noteCount > 0" :content="noteCount">
          <v-icon :color="noteCount > 0 ? 'msaBlue' : 'grey'" class="ml-4">
            mdi-sticker-text-outline
          </v-icon>
        </v-badge>
        <v-badge inline :content="attachmentCount" :value="attachmentCount > 0">
          <v-icon :color="attachmentCount > 0 ? 'orange' : 'grey'" class="ml-4">
            mdi-paperclip
          </v-icon>
        </v-badge>
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
      >
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-btn-toggle v-model="statusId" :mandatory="statusId > 0" dense>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.SATISFACTORY"
            active-class="msaBlue white--text"
            @click.stop
            :class="{ 'disable-events': !isEditable || caCounts.total > 0 }"
          >
            <v-icon
              :color="
                statusId == $constants.FORM_ELEMENT_STATUS.SATISFACTORY
                  ? 'white'
                  : undefined
              "
            >
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY"
            active-class="msaBlue white--text"
            @click.stop
            :class="{ 'disable-events': !isEditable || caCounts.total > 0 }"
            @click="$emit('showNewCa')"
          >
            <v-icon
              :color="
                statusId == $constants.FORM_ELEMENT_STATUS.UNSATISFACTORY
                  ? 'white'
                  : undefined
              "
            >
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            :value="$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE"
            active-class="msaBlue white--text"
            @click.stop
            :class="{ 'disable-events': !isEditable || caCounts.total > 0 }"
          >
            N/A
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="userResponseText"
          auto-grow
          dense
          counter
          maxlength="4000"
          outlined
          placeholder="Optional answer"
          rows="1"
          @click.stop
          :readonly="!isEditable"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'InspectionItem',
  props: {
    element: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
    users: {
      type: Array,
    },
  },
  computed: {
    imageCount() {
      return this.element.images.length;
    },
    noteCount() {
      return this.element.notes.length;
    },
    attachmentCount() {
      return this.element.attachments.length;
    },
    caCounts() {
      const result = {
        resolved: 0,
        unresolved: 0,
        high: 0,
        medium: 0,
        low: 0,
        unranked: 0,
        total: 0,
      };
      this.element.correctiveActions.forEach((ca) => {
        if (ca.statusId == 1) {
          result.resolved++;
        } else {
          result.unresolved++;
        }
        if (
          this.highCa.includes(
            `${ca.hazardRankSeverity}${ca.hazardRankProbability}`,
          )
        ) {
          result.high++;
        } else if (
          this.mediumCa.includes(
            `${ca.hazardRankSeverity}${ca.hazardRankProbability}`,
          )
        ) {
          result.medium++;
        } else if (
          this.lowCa.includes(
            `${ca.hazardRankSeverity}${ca.hazardRankProbability}`,
          )
        ) {
          result.low++;
        } else {
          result.unranked++;
        }
        result.total++;
      });
      return result;
    },
    statusId: {
      get() {
        return this.element.statusId;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'statusId',
          value,
        });
      },
    },
    userResponseText: {
      get() {
        return this.element.userResponseText;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'userResponseText',
          value,
        });
      },
    },
  },
  data() {
    return {
      highCa: ['1A', '1B', '1C', '1D', '2A', '2B', '2C', '3A', '3B', '4A'],
      mediumCa: ['2D', '3C', '3D', '4B', '4C'],
      lowCa: ['4D', '5A', '5B', '5C', '5D'],
    };
  },
};
</script>
